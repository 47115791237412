import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ViewMode = "original" | "both" | "transformed";
interface CoreInfoState {
  apiKey?: string;
  viewMode: ViewMode;
  shouldShowDiff: boolean;
}

const initialState: CoreInfoState = {
  apiKey: undefined,
  viewMode: "original",
  shouldShowDiff: false,
};

/**
 * Core info slice
 * responsible for page-wide settings,
 * like what mode we're vieiwing in,
 * and the API key the user has set
 */
const coreInfo = createSlice({
  name: "coreInfo",
  initialState,
  reducers: {
    setApiKey: (state, action: PayloadAction<string>) => {
      state.apiKey = action.payload;
    },
    setViewMode: (state, action: PayloadAction<ViewMode>) => {
      state.viewMode = action.payload;
    },
    setShouldShowDiff: (state, action: PayloadAction<boolean>) => {
      state.shouldShowDiff = action.payload;
    },
  },
});

export const { setApiKey, setViewMode, setShouldShowDiff } = coreInfo.actions;

export default coreInfo.reducer;
