import React from "react";
import "./index.css";
import {
  FileUpload,
  ConfigPanel,
  ButtonAppBar,
  BigCenter,
  Toolbar,
} from "./components";
import { Button, IconButton, Link, Tooltip } from "@mui/material";
import { PlayArrow, Clear, MenuBook } from "@mui/icons-material";
import {
  parseApiError,
  stringToCsv,
  parseUploadedFile,
  ConfigBuilderUtils,
  mergeDataForCombinedTableDisplay,
} from "./modules";
import { FileData } from "./types";
import { useClassifyMutation, useTransformMutation } from "./api";
import { useAppSelector, useAppDispatch } from "./redux/storeHooks";
import { VerticalTable } from "./views/VerticalTable";
import { setViewMode } from "./redux/coreDuck";
import { setEntitiesAndTransforms, reset } from "./redux/configDuck";
import { TransformType, Entities, Transforms } from "./types";
import { DOCS_LINK } from "./constants";

const NO_DATA_YET = { rows: [], columns: [] };

export const Main = () => {
  const dispatch = useAppDispatch();
  const { apiKey, viewMode } = useAppSelector((state) => state.core);
  const { locale, entities, transforms } = useAppSelector(
    (state) => state.config
  );

  const [uploadedFileName, setUploadedFileName] = React.useState<string>("");
  // note: file data is the parsed sample of the data, not the full file.
  const [uploadedFileData, setUploadedFileData] = React.useState<
    FileData | undefined
  >();

  const [
    classify,
    {
      isLoading: classifyIsLoading,
      isError: classifyIsError,
      isSuccess: classifyIsSuccess,
      data: classifyResults,
      error: classifyError,
    },
  ] = useClassifyMutation();

  const [
    transform,
    {
      isLoading: transformIsLoading,
      isSuccess: transformIsSuccess,
      isError: transformIsError,
      data: transformResults,
      error: transformError,
    },
  ] = useTransformMutation();

  const handleFileProcessing = async (file: File) => {
    try {
      const results = await parseUploadedFile(file);
      setUploadedFileName(file.name);
      setUploadedFileData(results);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (!uploadedFileData || !apiKey) return;

    classify({ apiKey, data: uploadedFileData })
      .unwrap()
      .then((results) => {
        /**
         * takes the classify results when they come in
         * and sets up our local state, which is the
         * - initial classification value (e.g., field is first_name)
         * - initial transform value, derived from the presence of a classification field
         * The user can then change these values.
         */
        const { entities } = results;
        const fields = Object.keys(entities);

        const data = fields.reduce(
          (acc, field) => {
            const entity = entities[field];
            const transform = {
              type: !!entity ? TransformType.FAKE : TransformType.NULL,
            };
            acc.entities[field] = entity;
            acc.transforms[field] = transform;

            return acc;
          },
          { entities: {} as Entities, transforms: {} as Transforms }
        );

        dispatch(setEntitiesAndTransforms(data));
      })
      .catch((error) => {
        console.error("oopsy", error);
      });
  }, [uploadedFileData, apiKey, classify, dispatch]);

  const transformConfiguration = ConfigBuilderUtils.buildTV2Config(
    entities,
    transforms,
    locale
  );

  const handleTransformData = () => {
    const strConfig = transformConfiguration
      ? ConfigBuilderUtils.tv2ConfigToYAML(transformConfiguration)
      : undefined;

    if (!apiKey || !strConfig || !uploadedFileData || !classifyResults) return;
    const classifications =
      ConfigBuilderUtils.buildClassificationsForTransformApi(
        entities,
        classifyResults.all_entities
      );

    transform({
      apiKey,
      data: uploadedFileData,
      config: strConfig,
      classifications,
    })
      .unwrap()
      .then(() => {
        if (viewMode === "original") {
          dispatch(setViewMode("transformed"));
        }
      })
      .catch((error) => {
        console.error("transform error", error);
      });
  };

  const transformedData = !!transformResults?.transformed_data_source
    ? stringToCsv(transformResults.transformed_data_source)
    : undefined;

  const statusMessage = (): string => {
    if (classifyIsLoading) {
      return "Classifying your data...";
    }
    if (classifyIsError) {
      return `Error: ${
        classifyError
          ? parseApiError(classifyError)
          : "Unknown error occurred classifying data. Please reload app and try again."
      }`;
    }
    if (transformIsLoading) {
      return "Generating transform preview...";
    }
    if (transformIsError) {
      return `Error: ${
        transformError
          ? parseApiError(transformError)
          : "Unknown error occurred classifying data. Please reload app and try again."
      }`;
    }

    if (transformIsSuccess && !transformResults?.transformed_data_source) {
      // if it returns an empty string / response, no bueno.
      return `Error: Transform API returned no data. Please check your configuration and try again.`;
    }

    return "";
  };

  return (
    <div className="App">
      <ButtonAppBar />
      <Toolbar
        title={uploadedFileName}
        status={statusMessage()}
        isWorking={classifyIsLoading || transformIsLoading}
        titleAction={
          uploadedFileName && (
            <Tooltip title="Clear file and reset data">
              <IconButton
                size="small"
                aria-label="reset"
                onClick={() => {
                  setUploadedFileData(undefined);
                  setUploadedFileName("");
                  dispatch(reset());
                }}
                sx={{ mx: 0.5 }}
              >
                <Clear sx={{ width: 16, height: 16 }} />
              </IconButton>
            </Tooltip>
          )
        }
        actions={
          <>
            <Button
              type="submit"
              color="primary"
              size="small"
              variant="outlined"
              onClick={handleTransformData}
              disabled={!apiKey || !uploadedFileData || !classifyIsSuccess}
              startIcon={<PlayArrow />}
            >
              Run Preview
            </Button>
            <ConfigPanel config={transformConfiguration} />
            <Tooltip title="Go to docs">
              <IconButton
                component={Link}
                target="_blank"
                aria-label="Documentation"
                aria-controls="read-docs"
                aria-haspopup="true"
                href={DOCS_LINK}
                color="primary"
              >
                <MenuBook />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <VerticalTable
        isWorking={classifyIsLoading || transformIsLoading}
        data={
          !!uploadedFileData
            ? mergeDataForCombinedTableDisplay(
                uploadedFileData,
                transformedData
              )
            : NO_DATA_YET
        }
        allEntities={classifyResults?.all_entities}
        emptyState={
          !apiKey ? (
            <BigCenter>Please authenticate to get started.</BigCenter>
          ) : !uploadedFileData ? (
            <FileUpload onFileUpload={handleFileProcessing} />
          ) : (
            <div></div>
          )
        }
      />
    </div>
  );
};
