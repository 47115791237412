import * as React from "react";
import {
  Box,
  Drawer,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import { Code, LastPage, OpenInNew } from "@mui/icons-material";
import { CodeBlock, a11yLight } from "react-code-blocks";
import { ConfigBuilderUtils } from "../modules";
import { CONSOLE_LINK } from "../constants";

export const ConfigPanel = ({ config }: { config: Record<string, any> }) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const configStr = ConfigBuilderUtils.tv2ConfigToYAML(config);

  const handleCopyYaml = () => {
    if (configStr) {
      navigator.clipboard.writeText(configStr);
    }
  };

  const handleDownloadYaml = () => {
    if (configStr) {
      const element = document.createElement("a");
      const file = new Blob([configStr], { type: "text/yaml" });
      element.href = URL.createObjectURL(file);
      element.download = "gretel_config.yaml";
      document.body.appendChild(element); // Required for this to work in FireFox - GH copilot
      element.click();
    }
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={<Code />}
        onClick={toggleDrawer(true)}
      >
        Config
      </Button>
      <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 500 }} role="presentation">
          <AppBar position="static">
            <Toolbar variant="dense">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer(false)}
              >
                <LastPage />
              </IconButton>
              <Typography variant="h6" color="inherit" component="div">
                Gretel Config.yaml
              </Typography>
            </Toolbar>
          </AppBar>
          <CodeBlock
            text={configStr}
            language={"yaml"}
            showLineNumbers={true}
            theme={a11yLight}
          />
          <Box sx={{ p: 2 }} display="flex" gap={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleCopyYaml}
            >
              Copy
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleDownloadYaml}
            >
              Download
            </Button>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ pb: 2 }}>
              You can copy and paste this configuration into your Gretel
              project.
            </Typography>
            <Button href={CONSOLE_LINK} target="_blank" endIcon={<OpenInNew />}>
              Go to Gretel Console
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
