/**
 * This suffix is added to the end of one set of column data to help with
 * viewing the two columns / the difference between the two columns.
 */
export const XFORM_SUFFIX = "_x";

export const CONSOLE_LINK =
  process.env.VERCEL_ENV === "production"
    ? "https://console.gretel.ai/"
    : "https://console-eng.gretel.ai/";

export const CONSOLE_API_KEY_URL = `${CONSOLE_LINK}users/me/key`;

export const DOCS_LINK =
  "https://docs.gretel.ai/create-synthetic-data/models/transform/v2";
