export type TransformInfo = { type: TransformType; expression?: string };
// shape of entities in the config redux
export type Entities = { [field: string]: string | null };
// shape of transforms in the config redux
export type Transforms = { [field: string]: TransformInfo };

/**
 * The string representations of our transform options the user can choose from.
 * These get translated to the actual transform objects in the config.
 * Note: date_shift and partial_mask are not yet supported here.
 */
export enum TransformType {
  NULL = "null",
  FAKE = "fake",
  HASH = "hash",
  NORMALIZE = "normalize",
  EXPRESSION = "expression",
  // DATE_SHIFT = "date_shift",
  // PARTIAL_MASK = "partial_mask",
}

/**
 * shape of data after we parse from the user's uploaded file.
 */
export type FileData = {
  headers: string[];
  rows: Record<string, string>[];
};
