import React from "react";
import {
  Box,
  Button,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Popover,
  Chip,
  ListSubheader,
  ListItemText,
} from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { ExpressionForm } from "./ExpressionForm";
import { Functions as FunctionsIcon } from "@mui/icons-material";
import { TransformType, TransformInfo } from "../../types";
import { useAppSelector, useAppDispatch } from "../../redux/storeHooks";
import {
  selectAllFieldNames,
  makeSelectEntityAndTransformByField,
  setEntityByField,
  setTransformByField,
} from "../../redux/configDuck";

const NONE = "None";

// Note: we use the values provided by api,
// but hanging on to these as backups for now.
// maybe delete?
const DEFAULT_ENTITY_TYPES = [
  NONE,
  "gender",
  "company",
  "email",
  "address",
  "city",
  "phone_number",
  "street_address",
  "ssn",
  "iban",
  "name",
  "credit_card_number",
  "administrative_unit",
  "first_name",
  "last_name",
  "country",
  "postcode",
];

const TRANSFORM_OPTIONS = [
  { value: TransformType.NULL },
  { value: TransformType.FAKE },
  { value: TransformType.HASH },
  { value: TransformType.NORMALIZE },
  { value: TransformType.EXPRESSION },
];
const DEFAULT_ENTITY_TYPE = NONE;
const DEFAULT_TRANSFORM: TransformInfo = { type: TransformType.NULL };

export const ColumnControls = ({
  field,
  allEntities = DEFAULT_ENTITY_TYPES,
  disabled = false,
}: {
  /** field - the name of the column */
  field: string;
  /** allEntities - the full list of the entities provided by the classify api */
  allEntities?: string[];
  /** whether form fields should be disabled or not */
  disabled: boolean;
}) => {
  const dispatch = useAppDispatch();
  const columnNames = useAppSelector(selectAllFieldNames);
  const selectEntityAndTransformByField = makeSelectEntityAndTransformByField();

  const { entity = DEFAULT_ENTITY_TYPE, transform = DEFAULT_TRANSFORM } =
    useAppSelector((state) => selectEntityAndTransformByField(state, field));

  const expressionValue = transform.expression || "";
  /** make sure we have a 'none' value in there... */
  const entitiesWithNone = allEntities.some(
    (e) => e.toLowerCase() === NONE.toLowerCase()
  )
    ? allEntities
    : [NONE, ...allEntities];

  const handleChangeentity = (value: string | null) => {
    dispatch(
      setEntityByField({ field, entity: value === NONE ? null : value })
    );
  };

  const handleChangeTransformType = (value: string) => {
    // lil error check for my tired self
    if (value === null) {
      console.error('transorm type should be string "null", not `null`!!!');
    }
    dispatch(
      setTransformByField({
        field,
        transform: { type: value as TransformType, expression: undefined },
      })
    );
  };

  const handleChangeTransformExpression = (value?: string) => {
    dispatch(
      setTransformByField({
        field,
        transform: {
          type: TransformType.EXPRESSION,
          expression: !!value ? value : undefined,
        },
      })
    );
  };

  return (
    <Box sx={{ mb: 1 }}>
      <Stack spacing={1}>
        <Stack direction="column" spacing={1} alignItems="center">
          <FormControl variant="outlined" size="small" fullWidth>
            <Select
              labelId={`${field}.entity-type-label`}
              id={`${field}.entity-type`}
              label="Entity Type"
              name={`${field}.entity-type`}
              defaultValue={NONE}
              value={entity || NONE}
              onChange={(e) => handleChangeentity(e.target.value)}
              disabled={disabled}
            >
              <ListSubheader>
                <ListItemText primary="Entities" />
              </ListSubheader>
              {entitiesWithNone.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" fullWidth>
            <Select
              labelId={`${field}.transform-label`}
              id={`${field}.transform`}
              label="Transform"
              name={`${field}.transform`}
              value={
                transform.type === null ? TransformType.NULL : transform.type
              }
              onChange={(e) => handleChangeTransformType(e.target.value)}
              disabled={disabled}
            >
              <ListSubheader>
                <ListItemText primary="Transform type" />
              </ListSubheader>
              {TRANSFORM_OPTIONS.map(({ value }) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <>
          <PopupState variant="popover" popupId={`${field}-demo-popup-popover`}>
            {(popupState) => (
              <>
                {expressionValue !== "" ? (
                  <>
                    <Chip
                      icon={<FunctionsIcon />}
                      label={expressionValue}
                      onClick={popupState.open}
                      onDelete={() => handleChangeTransformExpression("")}
                    />
                  </>
                ) : (
                  <Button
                    variant="text"
                    size="small"
                    disabled={transform.type !== TransformType.EXPRESSION}
                    sx={{
                      opacity:
                        transform.type === TransformType.EXPRESSION ? 1 : 0,
                    }}
                    {...bindTrigger(popupState)}
                  >
                    Set expression
                  </Button>
                )}
                <Popover
                  {...bindPopover(popupState)}
                  sx={{ width: 600, maxWidth: 600 }}
                  transformOrigin={{ vertical: 16, horizontal: 16 }}
                >
                  <ExpressionForm
                    expression={expressionValue}
                    onChange={(value?: string) => {
                      handleChangeTransformExpression(value);
                      popupState.close();
                    }}
                    field={field}
                    entity={entity}
                    allColumnNames={columnNames}
                  />
                </Popover>
              </>
            )}
          </PopupState>
        </>
      </Stack>
    </Box>
  );
};
