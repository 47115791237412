import React from "react";
import { Box } from "@mui/material";

export const BigCenter = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          height: 300,
        }}
      >
        {children}
      </Box>
    </>
  );
};
