import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ClassificationInputData,
  ClassificationApiResult,
  TransformApiResult,
  TransformApiArgs,
  InferenceApiArgs,
  InferenceApiResult,
} from "./types";
import { csvToString } from "../modules/parseFile";

const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://api-dev.gretel.cloud/";

const ENDPOINTS = {
  classify: "v1/transform_v2/classify_ui",
  transform: "v1/transform_v2/transform_ui",
  inference: "v1/inference/natural/generate",
};

// Define a service using a base URL and expected endpoints
export const configuratorApi = createApi({
  reducerPath: "ConfiguratorApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    classify: builder.mutation<
      ClassificationApiResult,
      { apiKey: string; data: ClassificationInputData }
    >({
      query: ({ apiKey, data }) => {
        return {
          url: ENDPOINTS.classify,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: apiKey,
          },
          body: { data: csvToString(data) },
        };
      },
    }),
    transform: builder.mutation<TransformApiResult, TransformApiArgs>({
      query: ({ apiKey, data, config, classifications }) => {
        return {
          url: ENDPOINTS.transform,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: apiKey,
          },
          body: { data: csvToString(data), config, classifications },
        };
      },
    }),
    inference: builder.mutation<InferenceApiResult, InferenceApiArgs>({
      query: ({ apiKey, prompt }) => {
        const body = {
          model_id: "gretelai/gpt-qwen2-5-coder-7b",
          prompt: prompt,
          params: {
            max_tokens: 256,
            temperature: 0.6,
            top_k: 43,
            top_p: 0.9,
          },
        };

        return {
          url: ENDPOINTS.inference,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: apiKey,
          },
          body,
        };
      },
    }),
  }),
  tagTypes: [],
});

export const {
  useClassifyMutation,
  useTransformMutation,
  useInferenceMutation,
} = configuratorApi;
