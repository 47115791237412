import React from "react";
import { Button, Stack } from "@mui/material";
import { FolderOpen as FolderOpenIcon } from "@mui/icons-material";
import { BigCenter } from "./layouts/BigCenter";

export const FileUpload = ({
  onFileUpload,
}: {
  onFileUpload: (file: File) => void;
}) => {
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!!event.target.files && !!event.target.files[0]) {
      onFileUpload(event.target.files[0]);
    }
  };

  return (
    <>
      <BigCenter>
        <Stack spacing={2} alignItems="center">
          <FolderOpenIcon fontSize="large" />
          <span>Import a CSV file to get started.</span>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            htmlFor="file-select"
          >
            Choose a File
          </Button>
          <input
            id="file-select"
            accept=".csv"
            multiple
            type="file"
            onChange={handleFileUpload}
            className="visually-hidden"
          />
        </Stack>
      </BigCenter>
    </>
  );
};
