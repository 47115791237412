import { XFORM_SUFFIX } from "../constants";
import { FileData } from "../types";

type Row = Record<string, unknown>;
// From arron's code
/**
 * Combines two data objects with the same columns into one dataset.
 * The second dataset's columns have a suffix applied.
 * @param {*} first - The first data object
 * @param {*} second - The second data object
 * @param {string?} suffix - The suffix to apply to the second dataset's columns
 * @returns {columns, rows}
 */

export const mergeDataForCombinedTableDisplay = (
  first: FileData,
  second?: FileData
) => {
  const firstFormatted = {
    rows: first.rows,
    columns: first.headers.map((header) => ({ field: header })),
  };

  const secondFormatted = {
    rows: second?.rows || [],
    columns: second?.headers.map((header) => ({ field: header })) || [],
  };

  return mergeData(firstFormatted, secondFormatted);
};

export const mergeData = (
  first: {
    columns: { field: string }[];
    rows: Row[];
  },
  second: { rows: Row[] },
  suffix = XFORM_SUFFIX
): {
  columns: { field: string; headerName: string }[];
  rows: Row[];
} => {
  const firstColumns = first.columns.map((col) => col.field);

  const columns = firstColumns
    .map((col) => `${col},${col}${suffix}`)
    .join(",")
    .split(",")
    .map((header) => {
      return { field: header.trim(), headerName: header.trim() };
    });

  const secondRows = second.rows.map((row) => {
    const newRow: Row = {};
    Object.keys(row).forEach((key) => {
      newRow[key + suffix] = row[key];
    });
    return newRow;
  });

  const newRows = first.rows.map((row, idx) => {
    const newRow = { ...row, ...secondRows[idx] };

    return newRow;
  });

  return { columns, rows: newRows };
};
