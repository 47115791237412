import Papa from "papaparse";
import { FileData } from "../types";
// number of rows to sample from the file.
const NUM_ROWS = 10;

export const parseUploadedFile = (
  upload: File,
  limit = NUM_ROWS
): Promise<FileData> => {
  return new Promise((resolve, reject) => {
    if (upload.type !== "text/csv") {
      reject({ error: "Oops! File must be a CSV file." });
    }

    Papa.parse(upload, {
      preview: limit,
      header: true,
      skipEmptyLines: true,
      transformHeader: (header, index) => {
        if (!header || !header.trim()) {
          return `UNNAMED_COLUMN_${index}`;
        }
        header.trim();
        return header;
      },
      complete: ({ data, meta }) => {
        const [...rows] = data as Record<string, string>[];
        if (!meta?.fields) {
          return;
        }
        const headers = meta.fields;
        resolve({ headers, rows });
      },
      error(error) {
        reject({ error: error.message });
      },
    });
  });
};

export const csvToString = (data: FileData): string => {
  return Papa.unparse({ fields: data.headers, data: data.rows });
};

export const stringToCsv = (data: string) => {
  const parsed = Papa.parse(data, { header: true, skipEmptyLines: true });
  if (parsed.data) {
    if (
      Array.isArray(parsed.data) &&
      parsed.data.length > 0 &&
      typeof parsed.data[0] === "object" &&
      parsed.data[0] !== null
    ) {
      const headers = Object.keys(parsed.data[0]);
      const rows = parsed.data as Record<string, string>[];
      return { headers, rows };
    }
  }
};
