import React from "react";
import {
  DataGrid,
  GridColumnGroupHeaderParams,
  GridColDef,
} from "@mui/x-data-grid";
import { XFORM_SUFFIX } from "../../constants";
import { ViewMode } from "../../redux/coreDuck";
import { useAppSelector } from "../../redux/storeHooks";
import { Box, Toolbar } from "@mui/material";
import { ColumnControls } from "./ColumnControls";
import { Diff } from "./Diff";
import DisplayControls from "./DisplayControls";
import { SelectLocale } from "../../components/SelectLocale";

const DEFAULT_COL_WIDTH = 125;

export const VerticalTable = ({
  data,
  allEntities,
  emptyState,
  isWorking,
}: {
  /** data - the merged set of uploaded data and transform results if present */
  data: { rows: Record<string, unknown>[]; columns: { field: string }[] };
  /**allEntities - all possible entities from classify api result */
  allEntities?: string[];
  /** emptyState - the JSX element to display when there is no data */
  emptyState: JSX.Element;
  /** isWorking - a boolean to indicate if the data is being processed in some way */
  isWorking: boolean;
}) => {
  const { rows, columns } = data;
  const viewMode = useAppSelector((state) => state.core.viewMode);
  const showDiff = useAppSelector((state) => state.core.shouldShowDiff);

  const columnVisibilityModel = React.useMemo(
    () => columnVisibility(columns, viewMode),
    [columns, viewMode]
  );
  const columnGrouping = React.useMemo(
    () => columnGroupingModel(columns, isWorking, allEntities),
    [columns, allEntities, isWorking]
  );

  const [selectedRow, setSelectedRow] = React.useState<string | number>("");

  const displayRows = rows.map((row, idx) => ({
    idx,
    ...row,
  }));

  const displayColumns = [
    {
      field: "idx",
      headerName: "#",
      type: "number",
      sortable: false,
      width: 24,
    },
    ...columns,
  ].map((cell) => ({
    width: viewMode === "both" ? DEFAULT_COL_WIDTH : DEFAULT_COL_WIDTH * 2,
    renderHeader: (params: { field: string }) =>
      getHeaderForField(params.field),
    renderCell: (params: {
      field: string;
      row: Record<string, unknown>;
      value: string;
    }) => {
      let prev_value = params.row[
        params.field.replace(XFORM_SUFFIX, "")
      ] as string;
      if (params.field.endsWith(XFORM_SUFFIX)) {
        return (
          <Diff
            string1={prev_value}
            string2={params.value}
            mode={showDiff ? "words" : "none"}
          />
        );
      }
      if (params.field === "idx") {
        return params.value + 1;
      }
      return params.value;
    },
    cellClassName: (params: { field: string }) => {
      if (params.field === "idx") {
        return "row-number-cell";
      }
      // Used for styling the transformed cells
      if (params.field.endsWith(XFORM_SUFFIX)) {
        return "xform-cell";
      }
      return "";
    },
    ...cell,
  }));

  const hasData = displayRows.length > 0;

  return (
    <>
      {hasData && (
        <Toolbar variant="dense">
          <SelectLocale />
          <div style={{ flex: 1 }} />
          <DisplayControls />
        </Toolbar>
      )}
      <DataGrid
        autoHeight
        hideFooter
        density={"compact"}
        showCellVerticalBorder
        showColumnVerticalBorder
        disableVirtualization // Must be disabled to prevent rendering issues
        columnGroupingModel={columnGrouping}
        columnVisibilityModel={columnVisibilityModel}
        rows={displayRows}
        columns={displayColumns as GridColDef<{ idx: number }>[]}
        getRowId={(row) => row.idx}
        columnGroupHeaderHeight={275} // note: arron had this as 'auto' but MUI only takes numbers here...
        slots={{ noRowsOverlay: () => emptyState }}
        sx={{
          "--DataGrid-overlayHeight": "300px",
          "[aria-selected='true'] .MuiDataGrid-cell": {
            paddingTop: "7px",
            paddingBottom: "7px",
          },
        }}
        onRowSelectionModelChange={(rows) => setSelectedRow(rows[0])}
        getRowHeight={({ id, densityFactor }) => {
          if (selectedRow !== "" && id === selectedRow) {
            return "auto";
          }
          return 52 * densityFactor;
        }}
      />
    </>
  );
};

const columnVisibility = (columns: { field: string }[], mode: ViewMode) => {
  return columns.reduce((acc: Record<string, boolean>, { field }) => {
    if (field.endsWith(XFORM_SUFFIX)) {
      acc[field] = mode === "both" || mode === "transformed";
    } else {
      acc[field] = mode === "both" || mode === "original";
    }
    return acc;
  }, {});
};

// In this table we group input and output columns together
// into a single column group.
const columnGroupingModel = (
  columns: { field: string }[],
  isWorking: boolean,
  allEntities?: string[]
): {
  groupId: string;
  children: { field: string }[];
  headerClassName: "column-group-header";
  renderHeaderGroup: (params: GridColumnGroupHeaderParams) => React.ReactNode;
}[] => {
  return columns
    .filter(({ field }) => !field.includes(XFORM_SUFFIX))
    .map(({ field }) => {
      return {
        groupId: field,
        children: [{ field }, { field: field + XFORM_SUFFIX }],
        headerClassName: "column-group-header",
        renderHeaderGroup: (params: {
          headerName?: string;
          groupId: string | null;
        }) => {
          return (
            <Box sx={{ flex: 1, justifyItems: "start" }}>
              <Box
                sx={{
                  my: 1,
                  pb: 1,
                  borderBottom: "1px solid var(--DataGrid-rowBorderColor)",
                }}
              >
                <strong>{params.headerName}</strong>
              </Box>
              <ColumnControls
                field={field}
                allEntities={allEntities}
                disabled={isWorking}
              />
            </Box>
          );
        },
      };
    });
};

const getHeaderForField = (field: string) => {
  if (field === "idx") {
    return "#";
  }
  if (field.endsWith(XFORM_SUFFIX)) {
    return "Transformed";
  }
  return "Original";
};
