import React from "react";
import { Stack, Toolbar as MUIToolbar, Typography } from "@mui/material";
import { LinearProgressBar } from "./LinearProgressBar";

export const Toolbar = ({
  actions,
  status,
  title,
  titleAction,
  isWorking,
}: {
  actions: React.ReactNode;
  status: string;
  title: string;
  titleAction: React.ReactNode;
  isWorking: boolean;
}) => {
  return (
    <>
      <MUIToolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Stack direction={"row"} alignItems="center">
          <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
          {titleAction}
          {status && (
            <Typography sx={{ fontStyle: "italic" }}> — {status}</Typography>
          )}
        </Stack>
        <Stack direction={"row"} gap={1}>
          {actions}
        </Stack>
      </MUIToolbar>
      {isWorking && <LinearProgressBar />}
    </>
  );
};
