import React from "react";
import {
  Box,
  Toolbar,
  Typography,
  Button,
  AppBar,
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { CheckCircleOutlined, KeyOutlined } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../redux/storeHooks";
import { setApiKey } from "../redux/coreDuck";
import Logo from "../logo.svg";
import { CONSOLE_API_KEY_URL } from "../constants";

export const ButtonAppBar = () => {
  const apiKey = useAppSelector((state) => state.core.apiKey);
  const dispatch = useAppDispatch();

  const [showAuth, setShowAuth] = React.useState<boolean>(false);

  // Show the auth dialog automatically if the user hasn't authenticated
  React.useEffect(() => {
    if (!apiKey) {
      setShowAuth(true);
    }
  }, []);

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Box
            sx={{
              pr: 2,
              mr: 2,
              borderRight: "1px solid",
              borderRightColor: "rgba(255, 255, 255, 0.3)",
              height: 24,
              position: "relative",
              top: 2,
            }}
          >
            <img src={Logo} alt="Gretel Logo" height={24} />
          </Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Transform Configurator
          </Typography>
          <Button
            color="inherit"
            onClick={() => setShowAuth(true)}
            startIcon={apiKey ? <CheckCircleOutlined /> : <KeyOutlined />}
          >
            {apiKey ? "Authenticated" : "Authenticate"}
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog
        open={showAuth}
        onClose={() => setShowAuth(false)}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const apiKey = formJson.apiKey;
            dispatch(setApiKey(apiKey || ""));
            setShowAuth(false);
          },
        }}
      >
        <DialogTitle>Provide Your Gretel API Key To Authenticate</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Your API key is a secret token that allows you to authenticate with
            the Gretel API. You can find your key in the{" "}
            <a href={CONSOLE_API_KEY_URL} rel="noreferrer" target="_blank">
              Gretel Console.
            </a>
          </DialogContentText>
          <TextField
            id="apiKey"
            name="apiKey"
            type="password"
            defaultValue={apiKey}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
