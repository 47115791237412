import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";

// NOTE: This file mostly rippped from Console's src/common/utils/formatters/other.ts
type Stringifiable<T = object> = T extends { toString(): string } ? T : never;

/**
 * @see https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
 * Note: Ideally, we only pass the error types into the formatter specified in TS. In reality, catch blocks typically
 * set the error type to `any`, so its possible we pass something different or even undefined in here.
 * */
export const parseApiError = (err: FetchBaseQueryError | SerializedError) => {
  if (!err) {
    return parseGenericError("Unexpected error");
  }

  if (typeof err === "string") {
    return parseGenericError(err);
  } else if ("data" in err && err.data != null) {
    if (typeof err.data === "string") {
      return parseGenericError(err.data);
    } else if (Array.isArray(err.data)) {
      return parseGenericError(err.data);
    } else if (typeof err.data === "object") {
      if ("error" in err.data && typeof err.data.error === "string") {
        return parseGenericError(err.data.error);
      } else {
        return parseGenericError(err.data);
      }
    }
  } else if ("error" in err) {
    return parseGenericError(err.error);
  }

  return "Unknown";
};

/**
 * @note use `Formatters.Other.rtkqError()` to handle query errors directly!
 * @todo Handle `context` key? */
const parseGenericError = (
  error?: string | string[] | { message: string } | Stringifiable
) => {
  if (!error) return undefined;
  if (typeof error === "string") {
    return error;
  }
  if (Array.isArray(error)) {
    return error.join(", ");
  }
  if (typeof error === "object") {
    if ("message" in error) {
      return error.message;
    } else {
      return error.toString();
    }
  }
};
