import { stringify } from "yaml";
import { TransformType, Entities, Transforms } from "../types";
import { ClassificationApiResult } from "../api/types";

const tv2ConfigToYAML = (config: Record<string, any>) => {
  try {
    return stringify(config);
  } catch (error) {
    console.error("Unable to convert config to YAML", error);
  }
};

/**
 * builds the yaml config (as an object) based on the entity classifications
 * and transformation values for each field in the table.
 * stefan says that "fake" is the only transformation that REQUIRES
 * an entity type to be set.
 */
const buildTV2Config = (
  entities: Entities,
  transforms: Transforms,
  locale: string = "en_US"
) => {
  const entitiesList = getUniqueEntities(entities);
  const transformations = getTransformations(transforms, entities);

  const config = {
    schema_version: "1.0",
    models: [
      {
        transform_v2: {
          data_source: "_",
          globals: {
            locales: [locale],
            classify: {
              enable: true,
              entities: entitiesList,
            },
          },
          steps: [{ rows: { update: transformations } }],
        },
      },
    ],
  };

  return config;
};

const getUniqueEntities = (entitiesByField: Entities): string[] => {
  const entities = Object.values(entitiesByField);
  const all = entities.filter((entity): entity is string => !!entity);
  return Array.from(new Set(all));
};

const getTransformations = (
  transformsByField: Transforms,
  entitiesByField: Entities
) => {
  const fieldsWithTransforms = Object.entries(transformsByField).filter(
    ([field, { type }]) => !!type && type !== TransformType.NULL
  );

  return fieldsWithTransforms.map(([field, transform]) => {
    const entity = entitiesByField[field];
    let value;

    if (transform.type === TransformType.FAKE) {
      if (!!entity) {
        value = "column.entity | fake";
      } else {
        value = "this | fake";
      }
    } else if (transform.type === TransformType.EXPRESSION) {
      if (transform.expression) {
        value = transform.expression;
      } else {
        // we can be here if the user selects the expression transform
        // but doesn't enter an expression value,
        // the user could also leave an empty expression to remove a value.
        value = "''";
      }
    } else if (transform.type === TransformType.HASH) {
      value = "this | hash | truncate(8)";
    } else {
      value = "this | " + transform.type;
    }
    return { name: field, value };
  });
};

/**
 * The transform api currently requires the classifications provided in the
 * shape of the classification api results. This function builds that shape.
 */
const buildClassificationsForTransformApi = (
  entitiesFromState: Entities,
  all_entities: string[]
): ClassificationApiResult => {
  const columns = Object.keys(entitiesFromState).reduce((acc, field) => {
    acc[field] = entitiesFromState[field] || "none";
    return acc;
  }, {} as Record<string, string>);

  return {
    all_entities,
    columns,
    entities: entitiesFromState,
  };
};

export { tv2ConfigToYAML, buildTV2Config, buildClassificationsForTransformApi };
