// Taken and modified from https://codesandbox.io/p/sandbox/react-jsdiff-l4ydo?file=%2FDiff.js%3A1%2C1-41%2C1
import React from "react";
import * as diff from "diff";

const styles = {
  added: {
    backgroundColor: "#CBD2FC",
    borderRadius: "3px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  removed: {
    color: "red",
    backgroundColor: "#fafafa",
    display: "none",
  },
};

export const Diff = ({
  string1 = "",
  string2 = "",
  mode = "characters",
}: {
  string1?: string;
  string2?: string;
  mode: "words" | "none" | "characters";
}) => {
  let groups: diff.Change[] = [];

  if (mode === "characters") groups = diff.diffChars(string1, string2);
  if (mode === "words") groups = diff.diffWords(string1, string2);

  if (mode === "none") {
    return <span>{string2}</span>;
  }

  const mappedNodes = groups.map((group, index) => {
    const { value, added, removed } = group;
    let nodeStyles;
    if (added) nodeStyles = styles.added;
    if (removed) nodeStyles = styles.removed;
    return (
      <span key={index} style={nodeStyles}>
        {value}
      </span>
    );
  });

  return <span>{mappedNodes}</span>;
};
