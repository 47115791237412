import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransformInfo, Transforms, Entities } from "../types";

interface ConfigState {
  locale: string;
  entities: Entities;
  transforms: Transforms;
}

const initialState: ConfigState = {
  locale: "en_US",
  entities: {},
  transforms: {},
};

/**
 * Config slice
 * responsible for storing info about the current configuration
 */
const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLocale(state, action: PayloadAction<string>) {
      state.locale = action.payload;
    },
    setEntities(state, action: PayloadAction<Entities>) {
      state.entities = action.payload;
    },
    setTransforms(state, action: PayloadAction<Transforms>) {
      state.transforms = action.payload;
    },
    setEntitiesAndTransforms(
      state,
      action: PayloadAction<{ entities: Entities; transforms: Transforms }>
    ) {
      state.entities = action.payload.entities;
      state.transforms = action.payload.transforms;
    },
    setEntityByField(
      state,
      action: PayloadAction<{ field: string; entity: string | null }>
    ) {
      state.entities[action.payload.field] = action.payload.entity;
    },
    setTransformByField(
      state,
      action: PayloadAction<{ field: string; transform: TransformInfo }>
    ) {
      state.transforms[action.payload.field] = action.payload.transform;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  setLocale,
  setEntities,
  setTransforms,
  setEntitiesAndTransforms,
  setTransformByField,
  setEntityByField,
  reset,
} = configSlice.actions;

export default configSlice.reducer;

// -- Selectors --

const selectEntities = (state: { config: ConfigState }) =>
  state.config.entities;

const selectTransforms = (state: { config: ConfigState }) =>
  state.config.transforms;

/**
 * Get all fields (column names)
 */
export const selectAllFieldNames = createSelector(
  selectEntities,
  (entities) => {
    return Object.keys(entities);
  }
);

/**
 * Get entity and transform value
 * given a particular field (column name)
 *
 * selectEntityAndTransformByField2(state, field)
 * See https://redux.js.org/usage/deriving-data-selectors#selector-factories
 */
export const makeSelectEntityAndTransformByField = () => {
  const selectEntityAndTransformByField = createSelector(
    [selectEntities, selectTransforms, (state, field) => field],
    (entities, transforms, field) => ({
      entity: entities[field],
      transform: transforms[field],
    })
  );
  return selectEntityAndTransformByField;
};
